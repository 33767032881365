import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect:'index'
  },
  {
    path: '/index',
    name: '首页',
    component: () => import( '../pages/index/index.vue'),
    meta:{
      title:'首页'
    }
  },
  {
    path: '/business',
    name: '商家入驻',
    component: () => import( '../pages/settled/business.vue'),
    meta:{
      title:'商家入驻'
    }
  },
  {
    path: '/hospital',
    name: '医院入驻',
    component: () => import( '../pages/settled/hospital.vue'),
    meta:{
      title:'医院入驻'
    }
  },
  {
    path: '/parmacy',
    name: '药店入驻',
    component: () => import( '../pages/settled/parmacy.vue'),
    meta:{
      title:'药店入驻'
    }
  },
  {
    path: '/news',
    name: '新闻咨询',
    component: () => import( '../pages/news/news.vue'),
    meta:{
      title:'新闻咨询'
    }
  },
  {
    path: '/newsInfo',
    name: '新闻详情',
    component: () => import( '../pages/news/newsInfo.vue'),
    meta:{
      title:'新闻详情'
    }
  },
  {
    path: '/recruit',
    name: '人才招聘',
    component: () => import( '../pages/recruit/recruit.vue'),
    meta:{
      title:'人才招聘'
    }
  },
  {
    path: '/recuitInfo',
    name: '人才招聘详情',
    component: () => import( '../pages/recruit/recuitInfo.vue'),
    meta:{
      title:'人才招聘详情'
    }
  },
  {
    path: '/about',
    name: '关于我们',
    component: () => import( '../pages/about/about.vue'),
    meta:{
      title:'关于我们'
    }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach((to,from,next)=>{
  if(to.meta.title){
    document.title=to.meta.title
  }
   window.scrollTo(0, 0)
  

})

export default router
