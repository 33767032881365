import Vue from 'vue'
import axiosA from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)



export const axios = axiosA.create({
    baseURL: 'http://www.anerjiankang.cn/api/',
    method: 'POST',
    header: {
        'Content-Type': 'application/json;charset=utf-8'
    },
})



