import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    uploadImg:'http://www.anerjiankang.cn/api/uploadImage',
    minHeight:'',//浏览器的高德
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
