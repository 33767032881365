<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    },
    created() {
      var t = window.devicePixelRatio   // 获取下载的缩放 125% -> 1.25    150% -> 1.5
      if (t != 1) {   // 如果进行了缩放，也就是不是1
        document.body.style.zoom = -0.6 * t + 1.55;   // 就去修改页面的缩放比例，这个公式我自己算的，不准确，勉强。
      }
      // this.store.state.minHeight= document.documentElement.clientHeight
      console.log(this.$store.state.minHeight=document.documentElement.clientHeight)
      console.log(this.$store.state.minHeight)
     
    },
   
  }
</script>

<style lang="scss" >
  @import './assets/css/common.scss';
  @import url(./assets/css/magic.min.css);
</style>